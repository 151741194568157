<template>
  <div class="wrap">
    <div>
      <div class="bread">
        <img
          class="add"
          src="@/assets/address.png"
        >
        <span>当前位置：</span>
        <router-link to="/">首页</router-link>
        <img
          class="right"
          src="@/assets/breadr.png"
        >
        <router-link to="/collection">我的收藏</router-link>
      </div>
      <div class="pro-wrap">
        <ul class="list">
          <li>
            <router-link to="/">
              <img src="@/assets/pro.png">
              <div>
                <p class="title ellipsis">已售255件</p>
                <div>
                  <span class="l">¥111</span>
                  <span class="r">已售255件</span>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/">
              <img src="@/assets/pro.png">
              <div>
                <p class="title ellipsis">已售255件</p>
                <div>
                  <span class="l">¥111</span>
                  <span class="r">已售255件</span>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/">
              <img src="@/assets/pro.png">
              <div>
                <p class="title ellipsis">已售255件</p>
                <div>
                  <span class="l">¥111</span>
                  <span class="r">已售255件</span>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/">
              <img src="@/assets/pro.png">
              <div>
                <p class="title ellipsis">已售255件</p>
                <div>
                  <span class="l">¥111</span>
                  <span class="r">已售255件</span>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/">
              <img src="@/assets/pro.png">
              <div>
                <p class="title ellipsis">已售255件</p>
                <div>
                  <span class="l">¥111</span>
                  <span class="r">已售255件</span>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Collection',
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .wrap {
    min-width: 1760px;
    background: #f2f2f2;
    > div {
      width: 1760px;
      margin: 0 auto;
      .pro-wrap {
        border-top: 1px solid #d8d8d8;
        padding-bottom: 60px;
        padding-top: 30px;
        .tab {
          margin-bottom: 40px;
        }
        .list {
          overflow: hidden;
          > li {
            float: left;
            width: 400px;
            margin: 0 32px 32px 0;
            border-radius: 2px;
            overflow: hidden;
            box-shadow: 0px 0px 10px 0px rgba(93, 93, 93, 0.1);
            > a {
              display: block;
              img {
                width: 100%;
                height: 288px;
              }
              > div {
                background: #fff;
                padding: 24px;
                .title {
                  font-size: 22px;
                  font-weight: 500;
                  color: #333333;
                  line-height: 30px;
                  margin-bottom: 9px;
                  font-family: PingFangSC-Medium, PingFang SC;
                }
                div {
                  overflow: hidden;
                  .l {
                    font-size: 18px;
                    color: #f2360d;
                    line-height: 25px;
                    font-family: PingFangSC-Regular, PingFang SC;
                  }
                  .r {
                    font-size: 16px;
                    color: #999999;
                    line-height: 22px;
                    font-family: PingFangSC-Regular, PingFang SC;
                  }
                }
              }
            }
          }
          > li:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .wrap {
    background: #f2f2f2;
  }
  .pro-wrap {
    background: #fff;
    padding: 15px;
    .tab {
      margin-bottom: 12px;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > li {
        width: calc(~'(100% - 5px) / 2');
        margin-bottom: 5px;
        border-radius: 1px;
        overflow: hidden;
        box-shadow: 0px 0px 5px 0px rgba(93, 93, 93, 0.1);
        > a {
          display: block;
          img {
            width: 100%;
          }
          > div {
            background: #fff;
            padding: 10px 5px;
            .title {
              font-size: 14px;
              font-weight: 500;
              color: #333333;
              line-height: 20px;
              margin-bottom: 7px;
              font-family: PingFangSC-Medium, PingFang SC;
            }
            div {
              overflow: hidden;
              .l {
                font-size: 13px;
                color: #f2360d;
                line-height: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
              }
              .r {
                font-size: 11px;
                color: #999999;
                line-height: 26px;
                font-family: PingFangSC-Regular, PingFang SC;
              }
            }
          }
        }
      }
      > li:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
</style>